/* ./src/index.css */
html {
  background: #374151;
}

@tailwind base;
@tailwind components;

.rhap_container {
  box-shadow: none;
  background: #374151;
}

.rhap_time {
  color: #d1d5db;
}

button:focus {
  outline: none;
}

.btn {
  @apply flex items-center justify-center whitespace-nowrap text-14 font-medium px-3 h-7 rounded border border-blue-500 text-gray-400 bg-transparent;
}

.btn:hover {
  @apply border-blue-400 text-gray-300;
}

.btn:active {
  @apply border-blue-500 text-gray-400;
}

.btn:disabled {
  @apply border-gray-400 text-gray-400;
}

.text-input {
  @apply flex items-center px-2 h-8 rounded border border-gray-300;
}

.text-input:focus {
  @apply outline-none border-blue-500;
}

@tailwind utilities;
